.date-picker-container-col {
  & .react-datepicker-popper {
    z-index: 9999;
  }

  & .react-datepicker {
    font-family: DigitalSerialBold;
    font-size: 12px;
    background-color: var(--color-background-primary);
    color: var(--color-text);
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
    border: none;
    margin: 8px 0;
    min-width: 450px;
    max-width: 910px;
    padding-bottom: 2rem;

    @media (max-width: 440px) {
      min-width: 250px;
      max-width: 320px;
      padding-bottom: 0;
    }

    & .react-datepicker__children-container {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 20rem;
    }

    & .react-datepicker__navigation,
    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
      & .react-datepicker__navigation-icon::before {
        height: 16px;
        width: 16px;
      }
    }

    & .react-datepicker__today-button {
      position: absolute;
      bottom: 0;
      border: none;
      background: var(--color-background-primary);
    }

    & .react-datepicker__month-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: var(--color-background-primary);

      & .react-datepicker__header {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: var(--color-background-primary);
        gap: 2rem;
        margin-top: 1.5rem;

        & .react-datepicker__current-month {
          color: var(--color-text);
        }

        & .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
          font-weight: 400;
          font-size: 1.3rem;
        }

        & .react-datepicker__header__dropdown {
          @media (min-width: 992px) {
            position: absolute;
            width: 200%;
            justify-content: center;
            z-index: 999;
          }

          & .react-datepicker__month-dropdown-container,
          .react-datepicker__year-dropdown-container {
            & .react-datepicker__month-read-view,
            .react-datepicker__year-read-view {
              & .react-datepicker__month-read-view--selected-month,
              .react-datepicker__year-read-view--selected-year {
                visibility: visible;
              }
            }

            & .react-datepicker__month-dropdown {
              width: 25%;
              left: 34%;
              background-color: var(--color-background-primary);
              box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
              border: none;
              padding: 8px;

              & .react-datepicker__month-option {
                padding: 8px;
              }
            }

            & .react-datepicker__year-dropdown {
              width: 15%;
              left: 45%;
              background-color: var(--color-background-primary);
              box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
              border: none;
              padding: 8px;

              & .react-datepicker__year-option {
                padding: 8px;
              }

              & .react-datepicker__year-option:last-child {
                display: none;
              }
            }
          }
        }

        & .react-datepicker__day-names {
          display: flex;
          justify-content: center;

          & .react-datepicker__day-name {
            width: 4.2rem;
            color: var(--color-text);
          }
        }
      }

      & .react-datepicker__month {
        display: flex;
        flex-direction: column;

        & .react-datepicker__week {
          display: flex;
          justify-content: center;

          & .react-datepicker__day.react-datepicker__day--outside-month {
            background-color: var(--color-background-primary);
            color: var(--color-lightest);
          }

          & .react-datepicker__day.react-datepicker__day--outside-month:hover {
            border: none;
            pointer-events: none;
            cursor: default;
            border-radius: 0;
            background-color: var(--color-background-primary);
            color: var(--color-lightest);
          }

          & .react-datepicker__day {
            width: 4.5rem;
            height: 65px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0;
            color: var(--color-text);
          }

          & .react-datepicker__day:hover {
            border-radius: 4rem;
            background-color: inherit;
            border: 2px solid var(--color-primary);
            color: var(--color-primary);
          }

          &
            .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start.react-datepicker__day--outside-month {
            pointer-events: none;
            background-color: var(--color-background-primary);
            color: var(--color-text);
          }

          &
            .react-datepicker__day--in-selecting-range:not(
              .react-datepicker__day--in-range,
              .react-datepicker__month-text--in-range,
              .react-datepicker__quarter-text--in-range,
              .react-datepicker__year-text--in-range,
              .react-datepicker__day--outside-month
            ) {
            background-color: var(--color-primary);
            border-radius: 0;
            color: var(--color-lightest);
          }

          & .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start {
            border-bottom-left-radius: 4rem;
            border-top-left-radius: 4rem;
            color: var(--color-darkest);
            background-color: var(--color-primary);
          }

          & .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
            border-bottom-right-radius: 4rem;
            border-top-right-radius: 4rem;

            &:hover {
              color: var(--color-darkest);
              font-size: 14px;
            }
          }

          & .react-datepicker__day.react-datepicker__day--in-range {
            border-radius: 0;
            background-color: var(--color-primary);
          }

          & .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--outside-month {
            background-color: var(--color-background-primary);
          }

          & .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--in-range {
            border-bottom-left-radius: 4rem;
            border-top-left-radius: 4rem;
            background-color: var(--color-primary);
          }

          & .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--in-range.react-datepicker__day--outside-month {
            background-color: var(--color-background-primary);
            pointer-events: none;
          }

          & .react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--in-range {
            border-bottom-right-radius: 4rem;
            border-top-right-radius: 4rem;
            background-color: var(--color-primary);
          }

          & .react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--in-range.react-datepicker__day--outside-month {
            background-color: var(--color-background-primary);
            pointer-events: none;
          }

          & .react-datepicker__day.react-datepicker__day--keyboard-selected {
            border-radius: 0;
            background-color: var(--color-background-primary);
          }

          & .react-datepicker__day.react-datepicker__day--selected {
            background-color: var(--color-primary);
            border-radius: 4rem;
            color: var(--color-lightest);
          }
        }
      }
    }
    & .react-datepicker__time-container {
      display: flex;
      flex-direction: column;
      & .react-datepicker__header--time {
        background-color: var(--color-background-primary);
      }
      & .react-datepicker__time {
        & .react-datepicker__time-box {
          & .react-datepicker__time-list {
            background-color: var(--color-background-primary);
            & .react-datepicker__time-list-item:hover {
              background-color: var(--color-primary-hover);
              color: var(--color-lightest);
            }
            & .react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
              background-color: var(--color-primary);
              color: var(--color-lightest);
              font-weight: inherit;
            }
          }
        }
      }
    }
  }
}

.react-datepicker__input-container {
  & > .form-control {
    min-width: 170px;
    font-size: 12px;
  }
}

.react-datepicker-time__header {
  color: var(--color-text);
}

div[class*='--disabled'] {
  cursor: not-allowed;
  color: var(--color-disabled) !important;
}
