@charset "UTF-8";

@font-face {
  font-family: DigitalSerialBold;
  src: url('/fonts/DigitalSerialBold_normal_normal.woff2') format('woff2'), /* Modern Browsers */ url('/fonts/DigitalSerialBold_normal_normal.woff') format('woff'); /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: OpenSansRegular;
  src: url('/fonts/open-sans-regular.ttf');
}

:root {
  --color-static-home-text: #444444;
  --color-static-home-primary: #65b330;
  --color-static-home-white: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

.main {
  background-color: var(--color-static-home-white);
}

a {
  text-decoration: none;
}

.header-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 10px;
}

footer {
  background-color: #f4f4f4;
  padding: min(64px, 10%) min(32px, 5%);
  padding-bottom: min(132px, 15%);
  display: flex;
  justify-content: center;
  position: relative;
}

.footer-grey-panel {
  position: absolute;
  right: 0;
  top: -48px;
  width: 33%;
  background-color: #f4f4f4;
  height: 48px;
}

.footer-logo {
  margin-bottom: 40px;
}

@media screen and (max-width: 1024px) {
  .footer-grey-panel {
    width: 16%;
  }
}

.footer-grey-panel:before {
  content: '';
  background-image: url('../../public/imgs/homepage/footer-panel-triangle.png');
  background-repeat: no-repeat;
  position: absolute;
  width: 48px;
  height: 48px;
  left: -48px;
}

footer .footer-content {
  max-width: 564px;
  width: 90%;
  display: flex;
  justify-content: center;
  gap: 32px;
}

.footer-content div {
  width: 50%;
  flex-grow: 1;
}

.footer-content .social-networks {
  margin-top: 20px;
  display: flex;
  gap: 8px;
}

.footer-content .static-links {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.static-links a {
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  color: #444;
}

.static-links a:hover {
  opacity: 0.9;
}

.footer-copyright {
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  color: #aaaaaa;
  position: absolute;
  bottom: 14%;
}

.static-home {
  h1 {
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: DigitalSerialBold;
    margin: 0;
    font-size: 48px;
  }

  span.efoy {
    color: var(--color-static-home-primary);
  }

  h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: DigitalSerialBold;
    color: var(--color-text);
    color: var(--color-static-home-text);
  }

  @media screen and (max-width: 1600px) {
    h2 {
      font-size: 28px;
      color: var(--color-text);
    }
  }

  p {
    font-size: 20px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1600px) {
    p {
      font-size: 16px;
    }
  }

}

.home-logo-link {
  display: block;
  min-height: 100%;
  display: flex;
  align-items: center;
}

.home-button {
  position: relative;
  overflow: hidden;
  background-color: var(--color-static-home-primary);
  height: 50px;
  padding: 0 24px;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  color: var(--color-static-home-white);
  text-decoration: none !important;
  width: 280px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%); /* Create the cut effect */
  -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%); /* For Safari */
}

.home-button:hover {
  opacity: 0.9;
  text-decoration: none !important;
  color: var(--color-static-home-white) !important;
}

.home-button--medium {
  position: relative;
  overflow: hidden;
  background-color: var(--color-static-home-primary);
  height: 46px;
  padding: 0 16px;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  color: var(--color-static-home-white);
  width: 224px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%); /* Create the cut effect */
  -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%); /* For Safari */
}

.home-button--medium:hover {
  opacity: 0.9;
  text-decoration: none !important;
  color: var(--color-static-home-white) !important;
}

.home-button-outline {
  font-family: 'Open Sans', sans-serif;
  background-color: transparent;
  background-image: url('../../public/imgs/homepage/register.png');
  background-repeat: no-repeat;
  height: 51px;
  width: 281px;
  padding: 0 24px;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  color: var(--color-static-home-primary);
  text-decoration: none !important;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.home-button-outline:hover {
  opacity: 0.7;
}

// .home-button or .home-button-outline
.home-button,
.home-button--medium {
  span {
    font-family: 'Open Sans', sans-serif;
  }
}

.home-hero {
  min-height: 596px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .home-hero {
    min-height: 400px;
  }
}

.home-info-panel {
  max-width: 1600px;
  margin: 0 auto;
  margin-top: 3%;
  background-color: var(--color-static-home-white);
}

.hero-image {
  position: absolute;
  right: 0;
  width: 60%;
  max-width: 1560px;
  height: auto;
  top: 5%;
}

@media screen and (max-width: 1600px) {
  .hero-image {
    width: 60%;
  }
}

@media screen and (max-width: 860px) {
  .hero-image {
    opacity: 0.3;
    width: 70%;
    top: 10%;
  }
}

@media screen and (max-width: 540px) {
  .hero-image {
    opacity: 0.3;
    width: 100%;
    top: 10%;
  }
}

.home-hero-container {
  position: relative;
  padding-left: 4%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
}

.home-button-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.panel-content {
  width: 77.6%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  gap: 8%;
  max-width: 1600px;
}

@media screen and (max-width: 1024px) {
  .panel-content {
    display: block;
    width: 90%;
  }
}

.panel-content > div {
  position: relative;
  flex-grow: 1;
  width: 50%;

  p {
    color: var(--color-static-home-text);
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
  }
}
home-button .panel-content > .panel-content-block {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
@media screen and (max-width: 1024px) {
  .panel-content > .panel-content-block {
    background-color: var(--color-static-home-white);
    padding: 10px;
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .panel-content > div {
    width: 100%;
  }
}

.up-to-date {
  position: relative;
  background-color: #fbfbfb;
  padding-top: min(7%, 112px);
  padding-bottom: min(7%, 112px);
  margin-top: min(10%, 100px);
}

.up-to-date .content > div > p {
  font-size: 18px;
}

.up-to-date .content > div > h2 {
  margin-bottom: 12px;
}

.green-line {
  position: absolute !important;
  left: 0;
  top: -10px;
  width: 10px;
  height: 74px;
  background: var(--color-static-home-primary);
}

@media screen and (max-width: 1024px) {
  .green-line {
    top: 0;
    height: 56px;
    width: 8px;
  }
}

@media screen and (min-width: 1600px) {
  .green-line {
    top: 0;
  }
}

div.green-line--small {
  position: absolute !important;
  left: -32px;
  top: -5px;
  width: 8px !important;
  height: 46px;
  background: var(--color-static-home-primary);
}

@media screen and (max-width: 1024px) {
  div.green-line--small {
    left: -6%;
    top: -4px;
    height: 40px;
  }
}

@media screen and (min-width: 1601px) {
  div.green-line--small {
    top: 0px;
  }
}

.green-line--big {
  position: absolute !important;
  left: 0;
  top: calc(50% - 105px);
  width: 16px !important;
  height: 211px;
  background: var(--color-static-home-primary);
}

@media screen and (max-width: 1024px) {
  .green-line--big {
    left: -10px;
    height: 100px;
    top: calc(50% - 50px);
  }
}

.green-line--big:before {
  content: '';
  background-image: url('../../public/imgs/homepage/triangle-before.png');
  background-repeat: no-repeat;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -16px;
}

.green-line--big:after {
  content: '';
  background-image: url('../../public/imgs/homepage/triangle-after.png');
  background-repeat: no-repeat;
  position: absolute;
  width: 16px;
  height: 16px;
  bottom: -16px;
}
