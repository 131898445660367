@media (min-width: 991px) {
  div.input-group {
    min-width: 22.8rem;
  }
}


button.btn-add-device {
  background: var(--color-primary);
  border-radius: var(--dim-border-radius);
  white-space: nowrap;
  min-height: 3rem;
  min-width: 11rem;
  margin-top: 0;
  margin-bottom: 0;

  span.btn-add-device-content {
    color: var(--color-lightest);
    font-size: 16px;
    font-family: DigitalSerialBold;
    text-align: center;
    text-transform: uppercase;
  }
}

div.input-group {
  background: var(--color-search-background);
  border: 1px solid var(--color-border);
  border-radius: var(--dim-border-radius);
  overflow: hidden;
  align-items: center;

  * {
    background: transparent;
    border: 0;
  }

  input.search-input {
    height: 3rem;

    &:focus {
      box-shadow: none;
      border: 0;
    }

    &::placeholder {
      color: var(--color-search-placeholder);
      font-size: 14px;
      letter-spacing: 0.2px;
      opacity: 0.8;
    }

    &:focus {
      @media (prefers-color-scheme: light) {
        background-color: transparent;
      }
    }
  }
}


@media (max-width: 991px) {
  div.device-table-info-content {
    display: none !important;
  }
}

a.no-style-link {
  color: inherit;
  text-decoration: inherit;
}

.license-expired {
  cursor: default;
  h3 {
    color: var(--color-disabled) !important;

    @media(prefers-color-scheme: dark) {
      color: var(--color-disabled-light) !important;
    }
  }
}
