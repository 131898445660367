@media (min-width: 576px) {
  div#edit-battery-dialog .modal-dialog, div#alerts-dialog .modal-dialog {
    max-width: 975px;
  }
}

.MuiSlider-mark[data-index="0"] {
  background-color: transparent;
  background-image: url('/imgs/Standard-Settings-Icon.svg');
  opacity: 1;
  height: 16px;
  width: 12px;
  top: 15px;
}

.MuiSlider-mark[data-index="1"], .MuiSlider-mark[data-index="2"] {
  display: none;
}

span.MuiSlider-markLabel {
  color: var(--color-text);
  font-size: 18px;
  font-family: digital-serial;
}

.MuiSlider-markLabel[data-index="1"], .MuiSlider-markLabel[data-index="2"] {
  top: 4px;
}

.MuiSlider-markLabel[data-index="1"] {
  left: -12% !important;
}

.MuiSlider-markLabel[data-index="2"] {
  left: 113% !important;
}

.slider-input-section {
  left: 230px;
  top: 3px;
}

.slider-input-section-mobile-view {
  left: 0px;
  top: 3px;
}

.slider-input-section, .slider-input-section-mobile-view {
  height: 25px;

  input {
    color: var(--color-text);
    font-size: 26px;
    font-family: digital-serial;
  }

  .MuiInputAdornment-positionEnd p {
    color: var(--color-text);
    font-size: 26px;
    font-family: digital-serial;
    margin: -4px 0px 0px -45px;
  }
}

.slider-input-section-mobile-view {
  input {
    font-size: 18px;
  }

  .MuiInputAdornment-positionEnd p {
    font-size: 18px;
    margin: -3px 0px 0px -30px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

button.battery-edit-button {
  float: right;
}

.MuiInput-underline:after {
  border-bottom: 2px solid var(--color-table-border) !important;
}

.Mui-focused:after {
  border-bottom: 2px solid var(--color-primary) !important;
}

@media (max-width: 1200px) {
  .MuiSlider-root {
    display: none !important;
  }
}
