
$primary: #65b330;
$danger: #fa5862;

//  Bootstrap theme overwrites
$theme-colors: (
  'primary': $primary,
  'danger': $danger
);

//  Bootstrap var overwrites
$btn-success-color: #fff;
$btn-close-color: #fff;
$btn-close-width: 1.6rem;
$btn-close-height: $btn-close-width;


// Override Bootstrap variables
$modal-content-bg: var(--color-background-primary);

// Default (light mode) variables
$nav-link-hover-color: var(--bs-nav-link-hover-color);;

// Dark mode variables
$dark-nav-link-hover-color:var(--color-primary);

// Define CSS custom properties for light mode
:root {
  --nav-link-hover-color: #{$nav-link-hover-color};
}

// Define CSS custom properties for dark mode using prefers-color-scheme
@media (prefers-color-scheme: dark) {
  :root {
    --nav-link-hover-color: #{$dark-nav-link-hover-color};
  }
}

$sfc-nav-link-hover-color: var(--nav-link-hover-color);
