@import './bootstrap_variables';

@media (max-width: 991px) {
  div.info-nav {
    display: none;
  }
}

div.info-nav {
  background: var(--color-primary);
  height: 40px;
  color: var(--color-lightest);
  padding-right: 15px;
  padding-left: 15px
}

a.info-links {
  color: var(--color-lightest);
  font-size: 13px;
}

ul.list-inline li:not(:first-child):before {
  content: " | ";
}

ul.info-list {
  height: 17px;
  margin-top: 10px;
}

#efoy-header .nav-link.active {
  color: var(--color-primary) !important;
  border-bottom-color: var(--color-primary);
}

header
{
    display: flex;
    flex-direction: column;

    width: 100%;

    &.fixed
    {
        position: fixed;
        z-index: 1060;
        top: 0;

        overflow: scroll;

        height: 100%;
    }
}

.info-bar
{
    color: var(--color-lightest);
    border-radius: 0;
    background: var(--color-primary);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .05);

    a
    {
        text-decoration: underline;

        color: var(--color-lightest);
    }
}

.dismiss-consent-button
{
    float: right;

    padding: 0 5px;

    cursor: pointer;
}

.disclaimer-alert
{
    padding: 20px 0 15px;

    text-align: center;
}

.navbar
{
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 70px;
    padding: 0 1rem;

    justify-content: space-between;
    align-items: center;

}

.bg-light {
  background-color: var(--color-background-primary) !important;
}

.navbar-nav-modal ul {
  width: 100%;
}

.navbar-nav-modal ul li {
  border-bottom: 2px solid;
  border-bottom-color: var(--color-table-border);
}

a.nav-link {
  background-color: transparent;
  border-bottom: .25rem solid transparent;
  color: var(--color-medium);
  font-family: DigitalSerialBold;
  font-size: 15px;
  letter-spacing: 0.2px;
}

a.nav-link:hover {
  text-decoration: none;
  color: $sfc-nav-link-hover-color;
}

a.nav-link-modal {
  font-size: 18px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px !important;
}

.nav-link-modal.active {
  border-bottom-color: transparent !important;
}

.nav-link {
  display: block;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.navbar {
  padding: 0 1rem;
  line-height: 1.8;
}

div.navbar-collapse {
  height: 100%;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  -webkit-box-align: center;
  align-items: center;
  justify-content: flex-end;
}

.collapse.show {
  display: block !important;
}
