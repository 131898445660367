@font-face {
  font-family: DigitalSerialBold;
  src: url('/fonts/DigitalSerialBold_normal_normal.woff2') format('woff2'), /* Modern Browsers */ url('/fonts/DigitalSerialBold_normal_normal.woff') format('woff'); /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: digital-serial;
  src: url('/fonts/digital-serial-regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: digital-serial;
  src: url('/fonts/digital-serial-bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: open-sans-regular;
  src: url('/fonts/open-sans-regular.ttf');
}

@font-face {
  font-family: open-sans-bold;
  src: url('/fonts/open-sans-bold.ttf');
}

:root {
  // main
  --color-primary: #65b330;
  --color-primary-hover: #4f8f25;
  --color-primary-30: #65b33030;
  --color-primary-50: #65b33050;
  --color-dark: #363636;
  --color-medium: #636363;
  --color-text-footer: #5a5956;
  --color-text: #4b4b4b;
  --color-text-muted: #6e6e6e;
  --color-text-light: #ababab;
  --color-heading-page: var(--color-text);
  --color-background: #f4f4f4;
  --color-disabled: #d2d2d2;
  --color-lightest: #fff;
  --color-darkest: #000;
  --color-error-text: #96353b;

  --dim-border-radius: 0.214rem;
  --dim-colum-gap: 0.75rem;

  // alerts
  --color-alert-success: var(--color-primary);
  --color-alert-warning: #ffaf4f;
  --color-alert-warning-dark: #856404;
  --color-alert-error: #fa5862;
  --color-success-border: #c3e6cb;
  --color-warning-border: #ffeeba;
  --color-warning-background: #fff3cd;
  --color-alert-background: #d4edda;

  // tables
  --color-table-border: #99999d;
  --color-table-header: #8e8e93;
  --color-table-header-border: var(--color-lightest);
  --color-table-odd-row: var(--color-lightest);
  --color-table-even-row: #e7e7e7;
  --color-table-text: #2e2f30;
  --color-background-light: #bebdbd;
  --color-accordion-table-border: #cfcfcf;

  // forms
  --color-search-placeholder: #8e8e93;
  --color-search-background: #e8e8e8;
  --color-border: rgba(211, 211, 211, 0.6);
  --color-border-light: #ced4da;
  --color-checkbox-background: var(--color-table-text);
  --color-checkbox-default-background: var(--color-lightest);

  // device detail
  --color-additional-value: var(--color-darkest);
  --color-graph-value: #69b12b;

  // backgrounds
  --color-background-primary: var(--color-lightest);
  --color-background-secondary: var(--color-background);

  --color-input-field: transparent;
}

@media (prefers-color-scheme: dark) {
  :root {
    // backgrounds
    --color-background-primary: #181a1b;
    --color-background-secondary: #1e2122;

    //  table
    --color-table-odd-row: #181a1b;
    --color-table-even-row: #26292a;
    --color-table-border: #3a4042;
    --color-table-header-border: #3a4042;
    --color-table-header: #3d4245;
    --color-accordion-table-border: #3a4042;

    //  texts
    --color-text: #cdcbc9;
    --color-medium: #cdcbc9;
    --color-text-muted: #bdb7af;
    --color-table-text: #cac6bf;
    --color-text-light: #b3aca2;

    //input
    --color-search-background: #25282a;

    //  forms
    --color-border-light: #bdb7af;
    --color-checkbox-default-background: var(--color-background-secondary);
    --color-checkbox-background: var(--color-background-secondary);
    --color-disabled: #232627;
    --color-disabled-light: var(--color-text-light);

    //  alert
    --color-alert-background: #325918;
    --color-success-border: #617365;
  }

  //  Use for FontAwesomeIcons
  .icon-dark-mode-ready {
    color: var(--color-text-muted) !important;
  }
}

@import './bootstrap_variables';
@import '~bootstrap/scss/bootstrap';
@import '~react-datepicker/dist/react-datepicker.css';

//  Bootstrap Table style override
thead,
tbody,
tfoot,
tr,
td,
th {
  border-style: hidden;
}

.table td,
.table th {
  padding: 0.75rem;
}

select > option {
  @media (prefers-color-scheme: dark) {
    background-color: var(--color-background-secondary);
  }
}

//  Bootstrap alerts
.alert.alert-success {
  --bs-alert-bg: var(--color-alert-background);
  --bs-alert-border-color: var(--color-success-border);

  @media (prefers-color-scheme: dark) {
    color: var(--color-text);
  }
}

.alert.alert-warning {
  --bs-alert-border-color: var(--color-warning-border);
  color: var(--color-alert-warning-dark);
  --bs-alert-bg: var(--color-warning-background);
}

.alert.alert-danger {
  @media (prefers-color-scheme: dark) {
    --bs-alert-border-color: #730209;
    color: #cd7076;
    --bs-alert-bg: #450206;
  }
}

.row > * {
  --bs-gutter-x: 1.8rem;
}

//  Nice dates popover
.nice-dates-popover {
  @media (max-width: 992px) {
    z-index: 12;
    max-width: min(476px, calc(100% - 84px));
  }
}

html,
body {
  background-color: var(--color-background-secondary);
  font-size: 14px;
  font-family: DigitalSerialBold;
  color: var(--color-text);
}

div.main-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

main {
  display: flex;
  padding: 2rem 0;
  flex: 1 0 auto;
}

div.body-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
}

.infinity-scroll {
  flex: 1 0 30rem;
}

@media (min-width: 1200px) {
  .body-container {
    max-width: 1140px;
  }

  .container {
    max-width: 1140px;
  }
}

.bg-content {
  background-color: var(--color-background-primary);
}

.modal-page,
.modal {
  z-index: 1060;
  display: block;

  .modal-header {
    .modal-title {
      margin: auto;
    }

    @media (prefers-color-scheme: dark) {
      border-bottom: 1px solid var(--color-background-primary);
    }

    .btn-close {
      font-size: 1.6rem;
      line-height: 0.2rem;
      padding: 0;
      display: inline-block;
      height: 2rem;
      text-shadow: 0 0px 0 var(--color-lightest);
      opacity: 1;
      color: var(--color-lightest);
      margin: 0;
      padding: 0;

      &:hover {
        filter: brightness(85%);
      }
    }
  }

  .modal-body {
    padding: 10px;
  }
}

.hide {
  display: none;
}

.error-message {
  margin: auto;
  text-align: center;
  width: 555px;
}

.error-message {
  margin: auto;
  text-align: center;
  width: 555px;
  background-color: var(--color-background-primary);
  padding: 50px 50px 50px;
}

@media (max-width: 992px) {
  .error-message {
    width: 460px;
    background-color: var(--color-background-secondary);
  }
}

.spinner-border {
  margin: auto;
}

//  Multisense modal dialog popup size.
.multisense-modal {
  & > .modal-dialog {
    max-width: 580px;
  }
}

div.alert-danger,
div.alert-warning,
div.alert-success {
  width: 100%;

  p {
    font-size: 14px;
    font-weight: 400;
  }
}

div.alert-success {
  p {
    color: rgb(33, 37, 41);
  }
}

.device-backlink {
  position: relative;
  right: 0;
  transform: rotate(180deg);
  opacity: 1;
  transition: all 0.3s;

  &:hover,
  &:active {
    right: 4px;
    opacity: 0.95;
    color: var(--color-primary-hover);
  }

  @media (max-width: 992px) {
    right: calc(100% - 2.5rem);
  }
}

.device-image {
  justify-self: stretch;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 18rem;
  }
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.apexcharts-canvas {
  background: var(--color-background-primary);
  margin-top: 1rem;
}

.btn {
  border-radius: var(--dim-border-radius);
  white-space: nowrap;
  min-height: 3rem;
  min-width: 3rem;
  text-transform: uppercase;
  margin: 0.2rem 0;
  box-shadow: none;
  border-width: 2px;
  border-style: solid;
  transition: transform 0.3s, opacity 0.3s;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: DigitalSerialBold;

  &.btn-light {
    border-color: var(--color-text-light);
    border-width: 1px;
    margin: 0.3rem 0;

    @media (prefers-color-scheme: dark) {
      border-color: var(--color-background-secondary) !important;
    }

    &.active,
    &:hover {
      color: var(--color-primary) !important;
      background: var(--color-background-secondary) !important;
      border-color: var(--color-text-light) !important;
      box-shadow: none !important;

      &:focus {
        box-shadow: none !important;
      }

      @media (prefers-color-scheme: dark) {
        border-color: var(--color-text-light) !important;
      }
    }
  }

  &.btn-success {
    color: var(--color-lightest);
  }

  // Toggle Button overwrites
  input:checked + &.btn-light {
    color: var(--color-primary) !important;
    background: var(--color-background-secondary) !important;
    box-shadow: none !important;
    border: 1px solid var(--color-border-light);
    font-size: 13px;

    &:focus {
      box-shadow: none !important;
    }
  }

  // Toggle Button overwrites
  input + &.btn-light {
    border: 1px solid var(--color-border-light);
    font-size: 13px;
  }

  &:enabled {
    &:hover {
      transform: scale(0.95);
      opacity: 0.9;
    }
  }

  &:active {
    outline: none;
    box-shadow: 0 0 0 0.5rem var(--color-primary-30);
  }

  &[disabled] {
    filter: saturate(0.5);
  }
}

.btn-group {
  margin: 0.2rem 0;
  vertical-align: middle;

  @media (prefers-color-scheme: dark) {
    border: 0.5px solid var(--color-table-border);

    .btn-check:not(:checked) + .btn-light {
      background-color: var(--color-background-primary);
      color: var(--color-text);
    }
  }

  .btn {
    margin: 0;
  }
}

.MuiInputBase-root {
  font-family: inherit !important;
}

.nopadding-left {
  padding-left: 0 !important;
}

.action-btn {
  &[disabled] {
    cursor: default;
    color: var(--color-disabled) !important;
  }
  &.disable {
    cursor: default;
    border-color: var(--color-disabled) !important;
    color: var(--color-disabled) !important;
    &:focus {
      box-shadow: none;
      outline: none;
    }

    & .btn {
      cursor: default;
      color: var(--color-disabled) !important;
    }
  }
}

button.action-btn.disable {
  background: var(--color-disabled) !important;
  border-color: var(--color-disabled) !important;
  color: var(--color-lightest) !important;
}

a {
  color: var(--color-primary);
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: var(--color-primary-hover);
  text-decoration: underline;
}

.row {
  margin-bottom: 1rem;
}

@keyframes mobileIconsAnimation {
  0% {
    opacity: 0;
    flex: 0.1;
  }
  100% {
    flex: 0.5;
    opacity: 1;
  }
}

.common-data-marker {
  opacity: 0.3;
}
.apexcharts-tooltip.apexcharts-active {
  backdrop-filter: blur(1px);
  background: rgba(255, 255, 255, 0.8) !important;
}

.apexcharts-xaxis-annotations {
  cursor: pointer;
}

.warning-annotation-tooltip {
  height: 100px;
  width: 100px;
  border: 5px solid red;
  z-index: 9999;
  position: relative;
  display: flex;
}

.form-group,
.form-control {
  font-size: 13px;
  color: var(--color-text);
}

.form-control {
  @media (prefers-color-scheme: dark) {
    background-color: transparent;
    color: var(--color-text);
    border-color: var(--color-medium);

    &:focus {
      background-color: transparent;
      color: var(--color-text);
      box-shadow: 0 0 0 0.25rem rgba(150, 150, 151, 0.25);
      outline: none;
      border-color: var(--color-medium);
    }
  }
}

.chrome-picker {
  svg {
    display: none;
  }
  * {
    font-family: open-sans-regular !important;
  }
}

.highcharts-tooltip-event {
  font-family: open-sans-regular;
  z-index: -10 !important;
  color: var(--color-lightest);
  text-decoration: none;
}

.highcharts-tooltip-event:hover {
  color: var(--color-lightest);
  text-decoration: none;
  padding: 10px;
  font-weight: bold;
}

.highcharts-plot-line-label:hover {
  z-index: 9 !important;
}

.highcharts-tooltip {
  z-index: 10 !important;
  padding: 10px;
  opacity: 0;
}

.tooltip-component {
  font-family: open-sans-regular;
  z-index: 10 !important;
  background-color: var(--color-background-primary);
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
}

.event-warning {
  font-weight: bold;
}

.hidden {
  display: none;
}

.modal-content {
  @media (prefers-color-scheme: dark) {
    border-color: var(--color-accordion-table-border);
    box-shadow: 0 5px 1rem rgba(0, 0, 0, 0.5);
  }
}
