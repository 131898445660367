p.add-efoy-message, p.add-efoy-steps {
  text-align: center;
  color: var(--color-text);
  font-size: 16px;
  font-family: open-sans-regular;
  font-weight: normal;
  line-height: 20px;
  margin: auto;
  margin-bottom: 20px;
}

input.input-errored {
  border: 1px solid var(--color-alert-error) !important;
}

textarea.input-errored {
  border: 1px solid var(--color-alert-error) !important;
}

p.field-error {
  margin: auto;
  width: 335px;
  color: var(--color-alert-error);
  font-size: 10px;
  font-family: open-sans-regular;
}

input.serial-number, input.confirm-efoy, input.efoy-password {
  height: 45px;
  width: 335px;
  background: var(--color-disabled);
  border: 1px solid rgba(211, 211, 211, 0.6);
  border-radius: 3px;
  margin: auto;
}

input.serial-number:focus, input.confirm-efoy:focus {
  background: var(--color-disabled);
}

input.serial-number::placeholder, input.serial-number:-ms-input-placeholder,
input.serial-number::-ms-input-placeholder {
  color: var(--color-text-light);
  font-size: 14px;
  font-family: open-sans-regular;
  font-weight: normal;
  letter-spacing: 0.2px;
}

button#cancel {
  width: 160px;
  height: 45px;
  border: 1px solid rgb(101, 179, 48);
  border-radius: 3px;
}

button#button-next {
  width: 160px;
  height: 45px;
  border-radius: 3px;
}

div.buttons-section {
  width: 335px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

p.modal-step {
  margin: auto;
  margin-bottom: 20px;
  width: 72px;
  height: 16px;
  color: var(--color-text-light);
  font-size: 13px;
  font-family: DigitalSerialBold;
  letter-spacing: 0.2px;
}

button.btn-connect-retry {
  width: 165px;
  height: 46px;
  background: rgb(101, 179, 48);
  border-radius: 3px;
  margin: auto;
}

span.btn-connect-retry-content {
  width: 215px;
  height: 20px;
  color: var(--color-lightest);
  font-size: 16px;
  font-family: DigitalSerialBold;
  text-align: center;
}

input.confirm-efoy,
input.confirm-efoy:placeholder-shown,
form .confirm-efoy::-webkit-input-placeholder,
form .confirm-efoy::-moz-placeholder,
form .confirm-efoy:-ms-input-placeholder,
form .confirm-efoy:placeholder {
  color: var(--color-primary) !important;
  font-size: 14px;
  font-family: ArialMT;
  letter-spacing: 0.2px;
}

button.btn-save-and-close, button#button-confirm-efoy {
  margin: auto;
  width: 335px;
}

i.loading-spinner {
  color: var(--color-primary);
  margin: auto;
}
