@media only screen and (min-width: 576px) {
    .bg-sm-white {
        background-color: var(--color-background-primary);
    }
}

input.form-input {
    height: 3rem;
    background: none;

    &::placeholder {
        color: var(--color-search-placeholder);
        font-size: 14px;
        font-family: digital-serial;
        letter-spacing: 0.2px;
        opacity: 0.8;
    }

    &:disabled {
        @media (prefers-color-scheme: dark) {
            background: var(--color-disabled);
            opacity: .7;
        }

    }
}

select.form-select {
    -webkit-appearance: menulist;
    height: 3rem;
    background: none;
    padding-left: 6.5px;
    border: 1px solid var(--color-border-light);

    &:focus {
        box-shadow: none;
    }

    &-placeholder {
        color: var(--color-search-placeholder);
        font-size: 14px;
        font-family: digital-serial;
        letter-spacing: 0.2px;
        opacity: 0.8;
    }

    &:focus {    
        color: var(--color-text);
        background-color: var(--color-background-primary);
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgb(101 179 48 / 25%);
    }

    option {
        padding: 0;
    }
}

textarea.form-textarea {
    background: none;
    font-family: open-sans-regular;
    font-size: 14px;

    &::placeholder {
        font-family: open-sans-regular !important;
        color: var(--color-search-placeholder);
        font-size: 14px;
        font-family: digital-serial;
        letter-spacing: 0.2px;
        opacity: 0.8;
    }
}
