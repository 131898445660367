// id should be from 0 to 5
// these are the numbers of series by default
$ids: 0, 1, 2, 3, 4, any !default;

:root {
  /* light mode */
  --chart-background-color: #ffffff;
  --chart-text-color: #292b2c;
  --chart-hilight-color: #0275d8;
  --tooltip-background-color: #ffffff;

  /* series colors */
  //  [old series colors are: '#98d051','#598236''#9634fe''var(--color-text-light)', '#4d73c3']
  --series-0-color: #98d051;
  --series-2-color: #598236;
  --series-1-color: #9634fe;
  --series-3-color: #e96b3a;
  --series-4-color: #4d73c3;
  --series-any-color: #c511c5;
}

.highcharts-background {
  fill: var(--chart-background-color);
}

span.highcharts-title {
  color: var(--chart-text-color);
  font-family: 'open-sans-regular';
  font-size: '20px';
  padding-top: '10px';
}

.highcharts-xaxis-labels text {
  font-size: 12px !important;
  fill: var(--chart-text-color) !important;
  opacity: 0.9;
}

.highcharts-legend-item span {
  fill: var(--chart-text-color) !important;
  color: var(--chart-text-color) !important;

  &:hover {
    color: var(--chart-hilight-color) !important;
  }
}

.highcharts-background {
  fill: var(--chart-background-color) !important;
}

.highcharts-grid-line {
  stroke: var(--chart-text-color);
  stroke-opacity: 0.2;
}

.highcharts-subtitle,
.highcharts-credits,
.highcharts-axis-title {
  fill-opacity: 0.7;
}

.highcharts-tooltip-box {
  background-color: var(--tooltip-background-color);
  box-sizing: border-box;
  box-shadow: var(--chart-text-color) 0px 0px 5px;

  & > table {
    color: var(--chart-text-color);
    min-width: min-content;
  }
}

.highcharts-column-series rect.highcharts-point {
  stroke: var(--tooltip-background-color);
}

.highcharts-XAxis-title {
  font-weight: bold;
  font-family: open-sans-regular;
  font-size: 13px;
}

@each $id in $ids {
  .highcharts-XAxis-title-#{$id} {
    font-weight: bold;
    font-family: open-sans-regular;
    font-size: 13px;
    opacity: 1;
    fill: unquote('var(--series-#{$id}-color)') !important;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    /* dark mode */
    --chart-background-color: #232527;
    --chart-text-color: #b3aca2;
    --chart-hilight-color: #8db4d6;
    --tooltip-background-color: #232527;

    /* series colors for dark mode */
    --series-0-color: #8db4d6;
    --series-1-color: #5cb85c;
    --series-2-color: #f0ad4e;
    --series-3-color: #d9534f;
    --series-4-color: #5bc0de;
    --series-any-color: #c511c5;
  }

  .highcharts-xaxis-labels text {
    font-size: 12px !important;
    fill: var(--chart-text-color) !important;
    opacity: 0.9;
  }

  .highcharts-legend-item > span {
    fill: var(--chart-text-color) !important;
    opacity: 0.9;
  }

  span.highcharts-title {
    color: var(--chart-text-color) !important;
    opacity: 0.9;
    font-family: 'open-sans-regular' !important;
    font-size: '20px';
    padding-top: '10px';
  }

  .highcharts-legend-item span {
    fill: var(--chart-text-color) !important;
    color: var(--chart-text-color) !important;

    &:hover {
      color: var(--chart-hilight-color) !important;
    }
  }

  .highcharts-background {
    fill: var(--chart-background-color) !important;
  }

  .highcharts-grid-line {
    stroke: var(--chart-text-color);
    stroke-opacity: 0.2 !important;
  }

  .highcharts-subtitle,
  .highcharts-credits,
  .highcharts-axis-title {
    fill-opacity: 0.7 !important;
  }

  .highcharts-tooltip-box {
    background-color: var(--tooltip-background-color);
    box-sizing: border-box;
    box-shadow: var(--chart-text-color) 0px 0px 5px;

    & > table {
      color: var(--chart-text-color);
      min-width: min-content;
    }
  }

  .highcharts-column-series rect.highcharts-point {
    stroke: var(--tooltip-background-color);
  }

  .highcharts-yaxis-labels > text {
    fill: var(--chart-text-color) !important;
    opacity: 0.9;
  }

  @each $id in $ids {
    .highcharts-XAxis-title-#{$id} {
      font-weight: bold;
      font-family: open-sans-regular;
      font-size: 13px;
      fill: unquote('var(--series-#{$id}-color)') !important;
    }
  }
}
