.navbar-toggler {
  border-color: transparent;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.blur-element {
  filter: blur(50px);
  -webkit-filter: blur(50px);
  -moz-filter: blur(50px);
  -ms-filter: blur(50px);
  -o-filter: blur(50px);
}

.nav-blur-color {
  background-color: var(--color-background-secondary) !important;
}

@media (min-width: 991px) {
  div.modal-link-menu {
    display: none !important;
  }

  .nav-blur-color {
    background-color: var(--color-lightest) !important;
  }

  .logo-image {
    display: block !important;
  }

  .blur-element {
    filter: blur(0);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -ms-filter: blur(0);
    -o-filter: blur(0);
  }
}

img.show, div.show-modal {
  display: block;
}

img.hide, div.hide-modal {
  display: none;
}

.modal-dialog-menu {
  max-width: 100%;
  height: 100%;
  margin: 0;
}

.modal-content-menu {
  height: 100%;
  border-radius: 0;
  border: none;
  background-color: rgba(255, 255, 255, 0.8);
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
}

.modal-header {
  border-radius: 1;
  background-color: var(--color-primary);
  color: var(--color-lightest);
  filter: blur(0);
  filter: blur(0);
  -webkit-filter: blur(0);
  -moz-filter: blur(0);
  -ms-filter: blur(0);
  -o-filter: blur(0);
}

.modal-body-menu {
  padding: 0;
  height: 100%;

  @media (prefers-color-scheme: dark) {
    background-color: var(--color-background-primary);
  }
}

.modal-body-menu p,
.modal-body-menu p a {
  color: var(--color-text);
  font-size: 16px;
  font-family: DigitalSerialBold;
  width: 100%;
}

.modal-body-menu p {
  border-bottom: 2px solid;
  border-bottom-color: var(--color-table-border);
  padding: 21px;
  padding-left: 26px;
}

.modal-body-menu a:focus {
  text-decoration: none;
}

.modal-link-menu {
  width: 100%;
  height: 100%;
}

.navbar-toggler {
  z-index: 1055;
  width: 48px;
  height: 48px;
  position: relative;

  &:focus, &:active {
    outline: 0;
  }

  span {
    background-color: var(--color-text);
    height: 3px;
    width: 32px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -1.5px 0 0 -16px;
    transition: all .25s ease-in-out;
  }

  span:nth-child(1) {
    transform: translate(0, -10px);
  }

  span:nth-child(3) {
    transform: translate(0, 10px);
  }

  &:not(.collapsed) {
    span {
      opacity: 0.9;
    }

    span:nth-child(1) {
      transform: translate(0) rotate(45deg);
    }

    span:nth-child(2) {
      transform: scale(0);
    }

    span:nth-child(3) {
      transform: translate(0) rotate(-45deg);
    }
  }
}
